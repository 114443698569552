<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">采购计划基本信息</el-col>
    </el-row>
    <el-form ref="Form" :model="planForm" :rules="rules" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="采购计划单号" prop="purchasePlanCode">
            <el-input
              v-model="planForm.purchasePlanCode"
              :placeholder="$t('page.inputPlaceholder')"
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态" prop="purchaseStatus">
            <el-select
              v-model="planForm.purchaseStatus"
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="fordisabled"
              style="width:100%;"
            >
              <el-option label="草稿" value="0" />
              <el-option label="审批中" value="1" />
              <el-option label="审批通过" value="2" />
              <el-option label="部分转订单" value="3" />
              <el-option label="已完成" value="4" />
              <el-option label="审批拒绝" value="5" />
              <el-option label="已作废" value="9" />
              <el-option label="运营部审批中" value="11" />
              <el-option label="计划部审批中" value="12" />
              <el-option label="财务部审批中" value="13" />
              <el-option label="采购部审批中" value="14" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="数量" prop="pairs">
            <el-input v-model="planForm.pairs" :placeholder="$t('page.inputPlaceholder')" :disabled="fordisabled" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备货渠道">
            <el-select v-model="planForm.stockChannelId" clearable filterable :disabled="fordisabled" style="width:100%;">
              <el-option
                v-for="item in StockChannelOptions"
                :key="item.channelId"
                :label="item.channelName"
                :value="item.channelId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="采购金额" prop="totalPurchaseAmount">
            <el-input
              v-model="planForm.totalPurchaseAmount"
              :placeholder="$t('page.inputPlaceholder')"
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="创建人" prop="createByName">
            <el-input
              v-model="planForm.createByName"
              :placeholder="$t('page.inputPlaceholder')"
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="BU" prop="operatorGroup">
            <el-input
              v-model="planForm.operatorGroup"
              :placeholder="$t('page.inputPlaceholder')"
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Operator" prop="operatorUser">
            <el-input
              v-model="planForm.operatorUser"
              :placeholder="$t('page.inputPlaceholder')"
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker
              v-model="planForm.createTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="请选择"
              default-value
              :disabled="fordisabled"
            />
            <!-- <el-input v-model="planForm.createTime" :placeholder="$t('page.inputPlaceholder')" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计划类型" prop="type">
            <el-radio-group v-model="planForm.type" :disabled="isEdit" @change="getPlanType">
              <el-radio :label="0">普通计划</el-radio>
              <el-radio :label="1">提前备货计划</el-radio>
              <el-radio :label="2">超买计划</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="planForm.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-row class="mb-2">
      <span style="font-size:15px;">标签选择：</span>
      <el-radio-group v-model="flag">
        <el-radio :label="1">使用美国标</el-radio>
        <el-radio :label="2">使用自主平台标</el-radio>
      </el-radio-group>
    </el-row> -->
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left: 25px">计划明细</span>
        <el-button icon="el-icon-plus" type="primary" class="ml-3" @click="downTemplate">模板下载</el-button>
        <el-upload
          ref="uploadForm"
          action=""
          class="el-button padding-none"
          :http-request="uploadFile"
          :limit="1"
          :show-file-list="false"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="fileChange"
        >
          <el-button type="primary">{{ $t("page.import") }}</el-button>
        </el-upload>
        <el-button type="primary" @click="addWavePicking()">批量新增</el-button>
        <el-button type="primary" @click="handleDelete()">删除</el-button>
        <el-button type="primary" @click="allclick()">一键赋值</el-button>
        <el-button v-if="$route.query.id" v-permission="'plan'" type="primary" @click="handleRefresh()">一键刷新</el-button>

      </el-col>
    </el-row>

    <el-table
      ref="uploadTableDataRef"
      class="mb-3"
      :summary-method="getTotal"
      show-summary
      :data="outboundDetailTable"
      :header-cell-style="{ background: '#fafafa' }"
      max-height="600px"
      row-key="sku"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="70" />
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="style,color,size" label="规格型号" width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column label="SKU" width="130" align="center">
        <!-- 存在id且是计划角色展示下拉 -->
        <template slot-scope="scope">
          <el-select
            v-if="scope.row.skuVOList&&scope.row.skuVOList.length"
            v-model="scope.row.sku"
            v-permission="'plan'"
            filterable
            :disabled="handleDisabled(scope.row)"
          >
            <el-option v-for="(e,i) in scope.row.skuVOList" :key="i" :label="e.sku" :value="e.sku" />
          </el-select>
          <span v-else v-permission="'plan'">{{ scope.row.sku }}</span>
          <!-- <span v-permission="'operate'"> {{ scope.row.sku }}</span> -->
        </template>

      </el-table-column>

      <el-table-column prop="platformName" label="平台" width="100" align="center" />
      <el-table-column prop="siteCode" label="站点" width="100" align="center" />
      <el-table-column prop="position" label="定位" width="100" align="center" />
      <el-table-column prop="negative" label="是否负现金流" width="140" align="center">
        <template slot-scope="scope">
          {{ scope.row.negative?'是':'否' }}
        </template>
      </el-table-column>
      <el-table-column width="120" prop="expectPairs" label="数量" align="center">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.expectPairs"
            style="text-align: center"
            @change="inputChange(scope.row)"
            @input="getTotalNumber('uploadTableDataRef', scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="shoeFoldFlag" label="鞋是否折叠" width="100" align="center" />
      <el-table-column prop="productPosition" label="产品定位" width="120" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.productPosition" :placeholder="$t('page.selectPlaceholder')">
            <el-option
              v-for="item in _getAllCommodityDict('PRODUCT_POSITION')"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column prop="expectDeliveryDate" label="期望交货日期" width="150" align="center">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.expectDeliveryDate"
            value-format="yyyy-MM-dd"
            :class="comparDate(scope.row.expectDeliveryDate)?'':'red'"
            type="date"
            style="width:160px;"
            placeholder="交货日期"
            @input="getTotalNumber('uploadTableDataRef', scope.row)"
          />
        </template>
      </el-table-column>

      <el-table-column label="翻单建议数量" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.reorderRecdQty" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="销售预测版本" width="130" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.versionCode" clearable filterable :placeholder="$t('page.selectPlaceholder')">
            <el-option
              v-for="item in dsbVertionOptions"
              :key="item.versionCode"
              :label="item.versionCode"
              :value="item.versionCode"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="预测销量" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.fcstSaleQty" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="预测退货" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.fcstReturnQty" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="库存汇总" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.totalInventoryPairs" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="PO/PR未结数量" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.prPoUnclearQty" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="RPS" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.rps" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="MPS" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.mps" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="近30天销量" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.recentSaleQty" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="近30天退货" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.recentReturnQty" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="采购LT" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.ltDays" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="TurnOVer" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.turnover" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="翻单周期" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.replenishCycle" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="平均退货率（%）" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.avgReturnRate" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="预留安全库存" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.reserveSafeStock" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
      <el-table-column label="预留外可用库存" width="130" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.notReservedAbleStock" :max="999999" :min="0" :precision="0" controls-position="right" style="width: 100%;" />
        </template>
      </el-table-column>
    </el-table>

    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <!-- <el-button @click="cancle()">{{ $t('title.cancel') }}</el-button> -->
      <el-button v-if="!isTransported" :loading="Loadingsave" type="primary" :disabled="!sidvoList.length" @click="save()">保存</el-button>
      <!-- <el-button v-if="!isTransported" v-permission="'operate'" type="primary" :disabled="!sidvoList.length" @click="onSubmit(0)">提交</el-button> -->
      <el-button v-if="!isTransported" v-permission="'plan'" type="primary" :disabled="!sidvoList.length" @click="onSubmit(1)">提交</el-button>
    </el-row>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="importDialog"
      width="550px"
      :close-on-click-modal="false"
      :before-close="closeImportDialog"
    >
      <span v-if="fileList != ''" class="info-message">{{ $t("page.ConfirmImport") }} {{ fileList[0].name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeImportDialog">取消</el-button>
        <el-button type="primary" :loading="loadingSubmit" @click="importDialogSubmit">导入详情</el-button>
      </span>
    </el-dialog>
    <!-- 导入详情 -->
    <el-dialog :visible.sync="uploadTableVisible" width="80%" title="导入详情" append-to-body>
      <el-table
        ref="uploadTableDataRef1"
        :data="importdata"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="550px"
        :row-class-name="tableRowClassName"
      >
        <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
          <template slot-scope="scope">{{
            (pager.current - 1) * pager.size + scope.$index + 1
          }}</template>
        </el-table-column>
        <el-table-column prop="error" label="错误信息" />
        <el-table-column prop="stockChannelName" label="备货渠道" />
        <el-table-column prop="style,color,size" label="规格型号" width="140" align="center">
          <template slot-scope="scope">
            {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
          </template>
        </el-table-column>
        <el-table-column prop="sku" label="SKU" align="center" width="350" />
        <el-table-column width="120" prop="expectPairs" label="数量" align="center">
          <template slot-scope="scope">
            <el-input
              v-model.number="scope.row.expectPairs"
              style="text-align: center"
              @change="inputChange(scope.row)"
              @input="getTotalNumber('uploadTableDataRef', scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="shoeFoldFlag" label="鞋是否折叠" width="100" align="center" />
        <el-table-column prop="productPosition" label="产品定位" width="110" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.productPosition" :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in _getAllCommodityDict('PRODUCT_POSITION')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column prop="expectDeliveryDate" label="期望交货日期" width="150" align="center">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.expectDeliveryDate"
              :class="comparDate(scope.row.expectDeliveryDate)?'':'red'"
              value-format="yyyy-MM-dd"
              style="width:160px;"
              type="date"
              placeholder="交货日期"
              @input="getTotalNumber('uploadTableDataRef', scope.row)"
            />
          </template>
        </el-table-column>

      </el-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="uploadTableVisible = false">取消</el-button>
        <el-button v-preventReClick type="primary" @click="submitImportTable">确定</el-button>
      </el-row>
    </el-dialog>
    <!-- 确认提交进审批流 -->
    <el-dialog :visible.sync="dialogVisible" title="提示" width="30%">
      <!-- 运营进入审批流 -->
      <span>{{ operatorType+''==='0'?'提交计划单会进入审批流，':'' }}您确认提交吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="Loadingcommit" @click="saveplanorder">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { refreshFnskuAndUpc, modifypurchaseplan, planimport,
  purchaseplanid, findDownloadUrl, addpurchaseplan,
  purchaseplansubmit, deletePlan, StockChannelList, dsbVersionList } from '@/api/scm-api'
import { deepClone, downloads } from '@/utils'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  mixins: [commodityInfoDict],
  data() {
    return {
      index: '',
      upcarr: [],
      arr: [],
      operatorType: '',
      submitImportTableFlag: false,
      loadingSubmit: false,
      flag: 2,
      dialogVisible: false,
      fordisabled: true, // 禁用文本框
      importdata: [],
      importdialog: false,
      importDetaildialog: false,
      file: '',
      fileList: [],
      importDialog: false,
      uploadTableVisible: false,
      planForm: {
        purchaseStatus: '0',
        createTime: '',
        totalPurchaseAmount: '',
        pairs: '',
        purchasePlanCode: '',
        type: 0
      },
      sidvoList: [],
      sidvoListSku: [],
      ids: [],
      items: [],
      styleOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      outboundDetailTable: [],
      Loadingsave: false,
      Loadingcommit: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      rules: {
        style: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        category1Code: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        turnover: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        ltDay: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        warehouseProcessDay: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        transportDay: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        platformShelfDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        safetyInventory: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      },
      StockChannelOptions: [],
      isEdit: false, // 修改时计划类型禁选
      dsbVertionOptions: []
    }
  },
  computed: {
    isTransported() {
      return this.planForm.purchaseStatus === '草稿' || this.planForm.purchaseStatus === '审批中'
    },
    user() {
      return this.$store.getters.authorities
    }
  },

  created() {
    this.$nextTick(() => {
      this.allclick()
      if (document.querySelector('#myTable')) {
        document.querySelector('#myTable').style.height = '351px'
      }
    })
  },
  mounted() {
    const { planForm, disabled } = this.$route.query
    if (disabled) {
      this.fordisabled = true
    }
    if (planForm) {
      this.planForm = JSON.parse(planForm)
    }

    this._purchaseplanid()
    const data = JSON.parse(sessionStorage.getItem('indatas'))
    if (data && data?.length) {
      this.outboundDetailTable = data
      this.planForm.stockChannelId = this.outboundDetailTable.map(item => item.stockChannelId)[0]
    }
    // 默认全选
    this.handleAllCheck()
    this._StockChannelList()
    this._dsbVersionList()
  },

  destroyed() {
    if (this.$route.name !== 'AllAddedOrder') {
      sessionStorage.removeItem('indatas')
    }
  },
  methods: {
    handleDisabled(row) {
      return row.skuVOList && !row.skuVOList.length
    },

    selectionChange(val) {
      this.sidvoList = val
      this.sidvoListSku = val && val.map(v => v.sku)
    },
    comparDate(dateValue) {
      // 交货日期大于当前日期   满足条件  不标红
      return new Date().getTime() < new Date(dateValue).getTime() + 3600 * 1000 * 24 - 8.64e7
    },
    handleAllCheck() {
      if (this.outboundDetailTable) {
        this.$nextTick(() => {
          for (let i = 0; i < this.outboundDetailTable.length; i++) {
            this.$refs.uploadTableDataRef.toggleRowSelection(
              this.outboundDetailTable[i],
              true
            )
          }
        })
      }
    },

    changeUPC(val, item) {
      if (!item.sku) {
        this.$message.warning('请先选择SKU')
        item.upc = ''
        return
      }
    },
    fnskuVisible(val, item) {
      if (val && item.sku) {
        item.fnskuVOList = item.fnskuVOList.filter(e => e.sku === item.sku) || []
        // item.fnskuVOList = item.fnskuVOList.map(item => { return { ...item, fnSku: '' } })
      }
    },
    changeFNSKU(val, item, index) {
      let optionIndex = ''
      // fnsku全是空/null的情况
      if (val.split('/')[0] === index + '') {
        optionIndex = Number(val.split('/')[1])
        val = ''
      }
      if (!item.sku) {
        this.$message.warning('请先选择SKU')
        item.fnsku = ''
        return
      }
      if (!val) {
        if (optionIndex !== '') {
          // console.log(item.UPCVOList.filter(e => e.sku === item.sku))
          item.upc = item.UPCVOList.filter(e => e.sku === item.sku).find((e, idx) => idx === optionIndex)?.upc
        } else {
          item.upc = item.UPCVOList.find((e, index) => Boolean(e.fnSku) === Boolean(val) && e.sku === item.sku).upc
        }
      } else {
        item.upc = item.UPCVOList.find(e => e.fnSku === val && e.sku === item.sku).upc
      }
    },
    changeSKU(val, item, index) {
      this.$set(this.arr, index, item.fnskuList)
      if (val) {
        this.$set(item, 'upc', '')
        this.$set(item, 'fnsku', '')
        item.fnskuVOList = this.arr[index].filter(item => item.sku === val)
      }
    },
    async handleRefresh() {
      const { datas } = await refreshFnskuAndUpc({ purchasePlanCode: this.planForm.purchasePlanCode })
      datas.length && datas.map(item => {
        this.outboundDetailTable.map((e, index) => {
          if (item.id === e.id) {
            this.$set(e, 'skuVOList', item.skuVOList)
            if (item.skuVOList && item.skuVOList.length === 1) {
              this.$set(e, 'sku', item.skuVOList[0].sku)
            }
          }
        })
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.error) {
        return 'warning-row'
      }
      return ''
    },
    allclick() {
      const data = this.$refs.uploadTableDataRef.selection
      // let pairs = 0
      if (data.length > 0) {
        // pairs = data[0].pairs
        // console.log(pairs)
        data.map(e => {
          e.expectDeliveryDate = data[0].expectDeliveryDate
          // e.orderTargetDay = data[0].orderTargetDay
          e.productPosition = data[0].productPosition
        })
        if (this.tableDatas) {
          this.tableDatas.push({})
          this.tableDatas.pop()
        }
        // this.tableDatas.splice(this.tableDatas.length - 1, 1)
      }
      // this.$refs[ref].toggleRowSelection(row, outPairsBoo)
      this.$refs.uploadTableDataRef.expectDeliveryDate = true
    },
    // getdatatime() { // 默认显示今天
    //   var d = new Date()
    //   this.planForm.createTime = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    // },
    // 修改查询
    async _purchaseplanid(query) {
      const { isQury, id } = this.$route.query
      if (id) {
        this.isEdit = true
        const { datas } = await purchaseplanid(id)
        this.planForm = datas
        datas.details = datas.details || []
        this.planForm.purchaseStatus = String(datas.purchaseStatus)
        if (isQury) {
          sessionStorage.setItem('indatas', JSON.stringify(datas.details))
          this.outboundDetailTable = datas.details
          this.outboundDetailTable.map(item => {
            if (item.skuVOList && item.skuVOList.length === 1) {
              this.$set(item, 'sku', item.skuVOList[0].sku)
            }
          })
          this.$nextTick(() => {
            for (let i = 0; i < this.outboundDetailTable.length; i++) {
              this.$refs.uploadTableDataRef.toggleRowSelection(
                this.outboundDetailTable[i],
                true
              )
            }
          })
        }
      } else {
        this.isEdit = false
      }
    },
    // 模板下载
    async downTemplate() {
      const { datas } = await findDownloadUrl('PURCHASE_PLAN_IMPORT_TEMPLATE')
      downloads(datas)
    },

    // 数量
    getTotalNumber(ref, row) {
      let outPairsBoo = true
      if (row.expectPairs <= 0) outPairsBoo = false
      this.$refs[ref].toggleRowSelection(row, outPairsBoo)
    },
    // 删除明细
    /*     handledelete() {
      this.items.map(e => {
        var index = this.outboundDetailTable.findIndex((i) => {
          return e.sku === i.sku && e.siteCode === i.siteCode && e.platformName === i.platformName
        })
        if (index !== -1) {
          this.outboundDetailTable.splice(index, 1)
        }
      })
      sessionStorage.setItem('indatas', JSON.stringify(this.outboundDetailTable))
    }, */
    async handleDelete() {
      this.ids = []
      this.items = []
      const data = this.$refs.uploadTableDataRef.selection
      const list = deepClone(this.outboundDetailTable)
      data.map(item => {
        item.id && this.ids.push(item.id)
        var index = list.findIndex(i => item.sku === i.sku && item.siteCode === i.siteCode && item.platformName === i.platformName)
        if (index !== -1) {
          list.splice(index, 1)
          this.outboundDetailTable.splice(index, 1)
          // sessionStorage.setItem('indatas', JSON.stringify(this.outboundDetailTable))
        }
      })
      sessionStorage.setItem('indatas', JSON.stringify(list))
      if (this.ids.length) {
        const { msg, code } = await deletePlan({ deleteIds: this.ids.toString(), purchasePlanCode: data[0].purchasePlanCode ? data[0].purchasePlanCode : 'null' })
        code === 0 ? this.$message.success(msg) : ''
      }
    },
    findItem(acc, e) {
      return acc.find(item => item.packageCode === e.packageCode && item.shoeFoldFlag === e.shoeFoldFlag)
    },
    // 保存采购计划
    save() {
      const data = this.sidvoList
      // || !item.packageCode
      if (data.some(item => !item.expectDeliveryDate || !item.shoeFoldFlag)) {
        this.$message({
          message: '【期望交货日期，鞋是否折叠】为必填项，请核对',
          type: 'warning'
        })
        return
      }
      let vo = {}
      const { purchasePlanCode, purchaseStatus, pairs, totalPurchaseAmount,
        createByName, createTime, remark, type } = this.planForm
      data.map((item, index) => {
        const { fnsku = '' } = item
        if (fnsku && fnsku.split('/')[0] + '' === index + '') item.fnsku = null
        return { ...item }
      })
      const { stockChannelId, stockChannelName } = this.outboundDetailTable[0] || {}
      vo = Object.assign({ details: data, deleteIds: this.ids, stockChannelId, stockChannelName }, { purchasePlanCode, purchaseStatus, pairs, totalPurchaseAmount, createByName, createTime, remark, type })
      const { id } = this.$route.query
      if (id) {
        vo.id = id
        this._modifypurchaseplan(vo)
      } else {
        this._addpurchaseplan(vo)
      }
    },

    // 修改采购计划
    async _modifypurchaseplan(vo) {
      this.Loadingsave = true
      const { code, msg } = await modifypurchaseplan(vo)

      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.$router.push({
          path: '../',
          append: true
        })
        this.Loadingsave = false
      } else { // 其他状态
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.Loadingsave = false
      }
    },
    // 保存采购计划
    async _addpurchaseplan(vo) {
      try {
        this.Loadingsave = true
        const { code, msg } = await addpurchaseplan(vo)

        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loadingsave = false
        }
      } finally {
        this.Loadingsave = false
      }
    },
    getCurrentData() {
      const { sidvoListSku, outboundDetailTable } = this
      const data = outboundDetailTable.filter(item => sidvoListSku.includes(item.sku))
      return data
    },
    // 获取计划类型
    getPlanType() {
      if (this.planForm.type !== 0) {
        this.outboundDetailTable = this.outboundDetailTable
      }
    },
    handleSubmit(operatorType) {
      this.operatorType = operatorType
      this.dialogVisible = true
    },
    // 提交
    onSubmit(operatorType) {
      const data = this.sidvoList

      // 获取第一个对象的 name 属性值
      const firstObjectStyle = data[0].style
      // 检查其余对象的 name 属性值是否与第一个对象相同
      const styleEqual = data.every(obj => obj.style === firstObjectStyle)
      if (!styleEqual) {
        this.$message({
          message: '请新增同一个style的明细',
          type: 'warning'
        })
        return
      }

      const flag = data.some(item => !item.expectDeliveryDate || !item.sku || !item.shoeFoldFlag)
      if (flag) {
        this.$message({
          message: '【期望交货日期，sku，鞋是否折叠】为必填项，请核对',
          type: 'warning'
        })
        return
      }
      if (data.some(item => !this.comparDate(item.expectDeliveryDate))) {
        this.$message({
          message: '期望交货日期不得小于或等于当前日期，请检查',
          type: 'warning'
        })
        return
      }
      // 存在EOL定位的SKU给用户弹出操作确认提示框，提示“存在EOL产品是否翻单”
      // 存在负现金流且Style为两季款的SKU时给用户弹出操作确认提示框，提示“存在负现金流两季款产品是否翻单”
      // 存在负现金流且Style为四季款的SKU时不允许翻单，提示“存在负现金流产品不允许翻单”
      const hasNegativeCashFlow = data.some(v => v.season !== '5' && v.negative)
      const hasEOLProduct = data.some(v => v.position === 'EOL')
      const hasNegativeCashFlowTwoSeasons = data.some(v => v.season === '5' && v.negative)
      if (hasNegativeCashFlowTwoSeasons) return this.$message.error('存在负现金流产品不允许翻单')
      if (hasEOLProduct || hasNegativeCashFlow) {
        const hasEOLProductMsg = '存在EOL产品是否翻单'
        const hasNegativeCashFlowMsg = '存在负现金流两季款产品是否翻单'
        const bothMsg = '存在EOL产品和负现金流两季款产品是否翻单'
        const msg = hasEOLProduct && hasNegativeCashFlow ? bothMsg : (hasEOLProduct ? hasEOLProductMsg : hasNegativeCashFlowMsg)

        this.$confirm(msg, '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleSubmit(operatorType)
        })
      } else {
        this.handleSubmit(operatorType)
      }
    },
    saveplanorder() {
      let vo = {}
      const data = this.sidvoList
      data.map(e => {
        if (e.expectPairs <= 0 || e.expectPairs === '') {
          this.$confirm('您输入的数量必须大于0', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          return
        }
      })
      data.map((item, index) => {
        const { fnsku = '' } = item
        if (fnsku && fnsku.split('/')[0] + '' === index + '') item.fnsku = null
        return { ...item }
      })
      const { purchasePlanCode, purchaseStatus, pairs, totalPurchaseAmount, createByName, createTime, remark, type } = this.planForm
      const { stockChannelId, stockChannelName } = this.outboundDetailTable[0] || {}
      vo = Object.assign({ operatorType: this.operatorType, stockChannelId, stockChannelName, details: data, roleCodes: JSON.stringify(this.$store.getters.authorities) }, { purchasePlanCode, purchaseStatus, pairs, totalPurchaseAmount, createByName, createTime, remark, type })
      const { id } = this.$route.query
      if (id) {
        vo.id = id
        this._purchaseplansubmit(vo)
      } else {
        this._purchaseplansubmit(vo)
      }
    },
    // 提交采购计划
    async _purchaseplansubmit(vo) {
      try {
        this.Loadingcommit = true
        const { code, msg } = await purchaseplansubmit(vo)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loadingcommit = false
        }
      } finally {
        this.dialogVisible = false
        this.Loadingcommit = false
      }
    },
    // 导入
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error(this.$t('page.UploadTypeTips'))
        return false
      }
    },
    // 导入
    async uploadFile() {
      try {
        this.loadingSubmit = true
        const form = new FormData()
        form.append('file', this.file)
        form.append('flag', this.flag)
        const { datas } = await planimport(form)
        if (Array.isArray(datas)) {
          datas.map(v => {
            v.outPairs = v.unSalesPairs
          })
          this.importdata = datas

          this.uploadTableVisible = true
          // 隐藏弹窗清空数据
          this.file = ''
          this.fileList = []

          this.importDialog = false
          this.loadingSubmit = false
        }
      } finally {
        this.loadingSubmit = false
      }
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.importDialog = true
        }
      } else {
        this.importDialog = false
      }
    },
    closeImportDialog() {
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    importDialogSubmit() {
      this.$refs.uploadForm.submit()
    },

    handleDeletedetails() {
      const data = this.$refs.uploadTableDataRef1.selection
      data.map(e => {
        var index = this.importdata.findIndex((i) => {
          return e.sku === i.sku && e.siteCode === i.siteCode && e.platformName === i.platformName
        })
        if (index !== -1) {
          this.importdata.splice(index, 1)
        }
      })
      sessionStorage.setItem('indatas', JSON.stringify(this.importdata))
    },
    submitImportTable() {
      const vo = { details: [] }
      if (!this.importdata.length) {
        return this.$message({
          message: '请至少选择一条明细导入',
          type: 'warning'
        })
      }

      if (this.importdata.some(item => item.error)) {
        return this.$confirm('列表中有错误信息不允许提交', '提示', {
          confirmButtonText: '确定'
        })
      }
      if (this.importdata.some(item => !this.comparDate(item.expectDeliveryDate))) {
        this.$confirm('期望交货日期不得小于或等于当前日期，请检查', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      vo.details = this.importdata
      // 与缓存现有数据合并
      if (sessionStorage.getItem('indatas')) {
        const andvo = JSON.parse(sessionStorage.getItem('indatas'))
        if (this.importdata.some(item => { return andvo.some(i => i.stockChannelId !== item.stockChannelId) })) return this.$message.warning('备货渠道须与当前已经添加的计划明细备货渠道一致')
        vo.details.map(e => {
          var index = andvo.findIndex((i) => {
            return e.sku === i.sku && e.siteCode === i.siteCode && e.platformName === i.platformName
          })
          if (index === -1) {
            andvo.push(e)
          } else {
            const { expectPairs } = e
            andvo[index].expectPairs = expectPairs
          }
        })
        sessionStorage.setItem('indatas', JSON.stringify(andvo))
        // this.outboundDetailTable = andvo
      } else {
        sessionStorage.setItem('indatas', JSON.stringify(vo.details))
        // this.outboundDetailTable = vo.details
      }
      this.outboundDetailTable === null ? this.outboundDetailTable = [] : ''
      this.outboundDetailTable = [...this.outboundDetailTable, ...vo.details]

      // 全选
      this.$nextTick(() => {
        for (let j = 0; j < this.outboundDetailTable.length; j++) {
          this.$refs.uploadTableDataRef1.toggleRowSelection(
            this.outboundDetailTable[j],
            true
          )
        }
      })
      this.uploadTableVisible = false
      this.planForm.stockChannelId = this.outboundDetailTable[0]?.stockChannelId
      this.$forceUpdate()
    },
    getTotal(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('合计:')
          return
        }
        if (column.property === 'purchasePrice' || column.property === 'purchaseAmount') {
          if (data) {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = Number(values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)).toFixed(2)
            } else {
              sums[index] = ''
            }
          }
        } else if (column.property === 'expectPairs') {
          if (data) {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
            } else {
              sums[index] = ''
            }
          }
        } else {
          sums[index] = ''
        }
      })
      this.planForm.pairs = sums[8]
      this.planForm.totalPurchaseAmount = sums[10]
      return sums
    },
    inputChange(row) {
      row.purchaseAmount = (row.purchasePrice * row.expectPairs).toFixed(2)
      row.totalInventoryAmount = row.purchasePrice * row.totalInventoryPairs
    },
    // 修改、查看
    addWavePicking(row, disabled) {
      sessionStorage.setItem('indatas', JSON.stringify(this.outboundDetailTable))
      this.$router.push({
        path: 'alladdedorder',
        append: true,
        query: { 'id': this.$route.query.id, 'disabled': this.$route.query.disabled, 'flag': this.flag, planForm: JSON.stringify(this.planForm) }
      })
    },
    // 备货渠道下拉框
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    async _dsbVersionList() {
      const { datas } = await dsbVersionList()
      this.dsbVertionOptions = datas
    }

  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table .warning-row {
  color: red !important;
}

/deep/ .red{
  .el-input__inner {
    border-color:red
  }
}
</style>
